.frame {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 80px;
  margin-top: 90px;
  transition: margin-left 0.3s ease-out;
}

.dark-theme .frame {
  background-color: #383d48;
}

.frame.menu-locked {
  margin-left: 300px;
}

.page-header {
  position: fixed;
  z-index: 29;
  width: 100%;
  height: 70px;
  background-color: white;
  box-shadow: 0 2px 30px 2px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: flex-end;
}

.dark-theme .page-header {
  background-color: #2c303b;
}
