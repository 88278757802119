.summary-column {
  cursor: zoom-in;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d5d5d5;

  &:last-child {
    border: none;
    cursor: help;
  }

  .papara,
  .havale {
    font-size: 0.8rem;
    opacity: 1;
    transition: opacity 0.25s;
  }

  .papara.hidden,
  .havale.hidden {
    opacity: 0;
  }

  .toplam {
    font-size: 1.6rem;
    margin-bottom: 20px;

    &.yatirim {
      color: #28d094;
    }

    &.cekim {
      color: #ff4961;
    }

    &.komisyon {
      color: #1e9ff2;
    }

    &.kalan {
      color: #28d094;
    }
  }
}

.summary-card {
  justify-content: space-between;

  &.bakiye {
    .amount,
    .icon {
      color: #ff9149;
    }
  }

  &.papara {
    .amount,
    .icon {
      color: #e91e63;
    }
  }

  &.banka {
    .amount,
    .icon {
      color: #1e9ff2;
    }
  }

  .amount {
    font-size: 1.2rem;
    justify-content: flex-start;
  }

  .text {
    font-size: 0.8rem;
    justify-content: flex-start;
  }

  .icon {
    height: 100%;
    align-items: center;
    font-size: 3rem;
  }
}

.dark-theme .summary-column {
  div:first-child,
  .papara,
  .havale {
    color: white;
  }
}

.dark-theme .summary-card {
  &.bakiye {
    .text {
      color: white;
    }
  }

  &.papara {
    .text {
      color: white;
    }
  }

  &.banka {
    .text {
      color: white;
    }
  }
}

.performance-box {
  height: fit-content;
  padding-top: 60px !important;
  padding-bottom: 20px;

  .performance-card {
    border-top: 1px solid #d5d5d5;

    &:last-child {
      border-bottom: 1px solid #d5d5d5;
    }

    .title {
      display: flex;
      flex-direction: row;
      width: 100%;

      padding: 20px;

      .number {
        flex-grow: 2;
        cursor: zoom-in;
      }

      .name {
        flex-grow: 3;
        cursor: zoom-in;
      }

      .total {
        flex-grow: 1;
        text-align: right;
        cursor: zoom-in;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      transition: height 0.25s ease;
      height: 210px;

      &.hidden {
        height: 0;
      }

      .detail {
        display: flex;
        flex-direction: row;
        margin: 10px 0;

        & > div {
          flex-grow: 1;
          padding-left: 30px;
        }
      }
    }
  }
}
