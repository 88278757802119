.box {
  box-shadow: 0 1px 15px 1px rgb(62 57 107 / 7%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  width: 100%;
  height: 100%;
  transition: height 0.25s ease;
}

.dark-theme .box {
  background-color: #2c303b;
  color: white;
}

.box.float {
  transition: transform 0.25s ease, box-shadow 0.25s ease;
}

.box.float:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0 14px 24px rgb(62 57 107 / 20%);
}

.box-title > * {
  color: #464855;
}

.dark-theme .box-title > * {
  color: white;
}
