.data-table {
  border-spacing: 0;
  min-height: 120px;

  tbody {
    tr {
      transition: opacity 0.2s ease-out;
    }

    .data-table-loader {
      border: 1px solid #e3ebf3;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      min-height: 60px;
      background-color: #ffffff00;
      display: flex;
      justify-content: center;
      align-items: center;

      .lds-facebook div {
        background-color: #a79bdf;
      }
    }
  }

  tbody.loading tr:not(.data-table-loader) {
    opacity: 0.2;
  }

  .data-table-header:first-child {
    border-left: 1px solid #e3ebf3;
  }

  .data-table-header {
    cursor: pointer;
    padding: 15px 20px;
    border: 1px solid #e3ebf3;
    border-left-width: 0;
    text-align: center;
    position: relative;
    user-select: none;

    .data-table-header-sort {
      top: 40%;
      position: absolute;
      right: 2px;
      width: auto;
      font-size: 0.7rem;
      height: auto;
      display: flex;

      i {
        color: #dbd7d7;
      }

      i.active {
        color: black;
      }
    }
  }

  .data-table-row {
    &.odd {
      background-color: rgba(245, 247, 250, 0.5);
    }

    &.onhover:hover {
      background-color: rgba(255, 162, 100, 0.47);
      cursor: pointer;
    }

    .data-table-column:first-child {
      border-left: 1px solid #e3ebf3;
    }

    .data-table-column {
      border: 1px solid #e3ebf3;
      border-left-width: 0;
      border-top-width: 0;
      text-align: center;
      padding: 15px 15px;

      .data-table-status-icon {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;

        &.approved {
          background-color: #e9f4e9;
          color: #429d38;
        }

        &.rejected {
          background-color: #fee8ec;
          color: red;
        }

        &.waiting {
          background-color: #ebe9fd;
          color: #7460ee;
        }

        &.tooltip {
          position: relative;
        }

        &.tooltip .tooltiptext {
          visibility: hidden;
          white-space: nowrap;
          background-color: #555;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 10px;
          position: absolute;
          z-index: 1;
          bottom: 125%;
          right: 0;
          opacity: 0;
          transition: opacity 0.2s;
          transition-delay: 0.2s;
          font-size: 0.8rem;
        }

        &.tooltip:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.dark-theme .data-table-status-icon {
  .tooltiptext {
    background-color: black !important;
  }
}

.dark-theme .data-table-row {
  &.odd {
    background-color: #3c495c;
  }
}

.data-table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  div:nth-child(1) {
    flex-grow: 1;
  }

  select {
    margin-right: 20px;
    height: 30px;
    outline: none;
    width: 60px;
  }

  select + .data-table-page-button {
    border-left: 1px solid #babfc7;
  }

  .data-table-page-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 30px;
    cursor: pointer;
    border: 1px solid #babfc7;
    border-left-width: 0;
    color: #1e9ff2;
    user-select: none;

    &:hover {
      background-color: #f9fafd;
    }

    &.prev {
      width: 80px;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    &.next {
      width: 80px;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .data-table-page-button.selected {
    background-color: #1e9ff2;
    color: white;
  }
}

.dark-theme .data-table-column,
.dark-theme .data-table-header {
  color: white;

  .data-table-header-sort {
    i {
      color: #62697a;
    }

    i.active {
      color: white;
    }
  }
}

.dark-theme .data-table-pagination {
  div:first-child {
    color: white;
  }
}
