.transfer-item-row {
  border: 1px solid black;
  border-radius: 0.35rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.gray {
    background-color: #efefef;
  }

  &.gray:hover {
    background-color: #e3e3e3;
  }

  &.yellow {
    background-color: #ffff9f;
  }

  &.yellow:hover {
    background-color: #f6f64a;
  }

  &.green {
    background-color: #96dd96;
  }

  &.green:hover {
    background-color: #73d973;
  }

  &.red {
    background-color: #ee2a2a;
  }

  &.red:hover {
    background-color: #b61616;
  }

  .transfer-item-field {
    justify-content: flex-start;
  }
}

.dark-theme .transfer-item-field {
  color: black;
}
