.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  transition: width 0.3s ease-in, height 0.3s ease-in, opacity 0.3s ease-in;
  margin: 10px 0;
  padding: 0 15px;
  min-height: 30px;
}

.row:first-child,
.row:last-child:not(:nth-child(2)) {
  margin: 0;
}

.row:last-child:nth-child(2) {
  margin-bottom: 0;
}
