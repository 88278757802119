.current-papara-list-segment {
  justify-content: flex-start;
  padding-left: 35px;
}

.dark-theme .current-papara-list-segment {
  color: white;
}

.current-papara-card-button {
  font-size: 0.9rem !important;
  background-color: white !important;
}

.dark-theme .current-papara-card-button {
  background-color: #010030 !important;
}
