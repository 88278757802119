.dark-theme .labeled-container {
  background-color: #2c303b;
  border: 1px solid white;

  &.active {
    background-color: #6aa3a5;
  }

  &.passive {
    background-color: #cc94d1;
  }

  & > div.label-div {
    color: white;
  }

  &.active > div.label-div,
  &.passive > div.label-div {
    color: black;
  }
}

.labeled-container {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.07);
  min-height: 80px;

  &.active {
    background-color: #c7f9c7;
  }

  &.passive {
    background-color: #f7dde1;
  }

  & > div {
    margin: 5px 0 5px 5px;
    width: 90%;
  }

  & > div.label-div {
    font-weight: 700;
    text-transform: uppercase;
    color: #2b335e;
  }

  & > div:last-child {
    & > input,
    & > select,
    & .react-datepicker__input-container > input {
      width: 100%;
      border: none;
      outline: none;
      font-size: 1rem;
    }
    & > textarea {
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
      font-size: 1rem;
      resize: none;
    }
    & > select {
      height: 100%;
    }
  }
}
