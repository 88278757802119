.info-message {
  display: flex;
  flex-direction: row;
  align-items: center;

  .info-message-icon {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    margin-right: 15px;

    &.success {
      background-color: #e9f4e9;
      color: #429d38;
    }

    &.warning {
      background-color: #fee8ec;
      color: red;
    }

    &.information {
      background-color: #1e9ff2;
      color: white;
    }
  }

  .info-message-text {
    display: flex;
    align-items: center;
    max-width: 500px;
    text-overflow: clip;
    margin-right: 15px;
  }
}

.dark-theme .info-message {
  .info-message-text {
    color: white;
  }
}
