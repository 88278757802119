.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login-form {
    display: flex;
    flex-direction: column;
  }
}
