@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-container-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .button-element {
    width: 100%;
    height: 100%;
    font-size: 1.1rem;
    border-radius: 0.35rem;
    border: none;
    color: white;
    cursor: pointer;
    outline: none;

    i {
      margin-right: 5px;
    }

    i:last-child {
      margin: 0;
    }

    &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.loading {
      cursor: default;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    white-space: nowrap;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
    font-size: 0.8rem;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.dark-theme .button-container-div {
  .tooltiptext {
    background-color: black;
  }
}
