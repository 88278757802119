* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #f3f4f9;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
}

body.dark-theme {
  background-color: #383d48;

  input,
  select,
  textarea {
    background-color: #62697a;
    color: white;
  }
}

a {
  all: unset;
}

input:not(.default),
select:not(.default) {
  font-size: 1rem;
  border-radius: 0.35rem;
  border: 1px solid #cacfe7;
  padding: 0.6rem 1.25rem;
  outline: none;
  width: 100%;
}

.dark-theme input::placeholder {
  color: #bdbdbd;
}
