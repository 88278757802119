.multiselect {
  position: relative;
  background-color: white;
  max-width: 100%;
  width: 100%;
}

.multiselect-container {
  max-width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  cursor: text;
  position: relative;
  overflow-y: scroll;
  padding-right: 25px;
  border-radius: 0.35rem;
  border: 1px solid #cacfe7;

  .multiselect-deselectall-button {
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .multiselect-box {
    height: fit-content;
    margin: 1px 3px;
    border-radius: 0.35rem;
    border: none;
    padding: 5px;
    display: flex;
    flex-direction: row;
    cursor: default;
    user-select: none;
    background-color: #ff9149;
    color: white;

    .multiselect-box-text {
      margin-right: 10px;
    }

    .multiselect-box-close {
      cursor: pointer;
    }
  }

  .multiselect-input {
    border: none;
    outline: none;
  }
}

.dark-theme .multiselect-container {
  background-color: #62697a;

  .multiselect-deselectall-button {
    color: white;
  }

  .multiselect-box {
    background-color: #010030;
  }

  .multiselect-input {
    background-color: #62697a;
    color: white;
  }
}

.multiselect-dropdown {
  border-radius: 0.35rem;
  border: 1px solid #cacfe7;
  position: absolute;
  top: 120px;
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  background-color: white;
  cursor: default;
  max-height: 150px;
  min-height: 30px;
  height: fit-content;
  overflow-y: scroll;

  .multiselect-dropdown-item {
    display: flex;
    align-items: center;
    min-height: 30px;
    user-select: none;
    cursor: pointer;
    padding: 5px;
  }

  .multiselect-dropdown-item:hover {
    background-color: #e0e0e0;
  }
}

.dark-theme .multiselect-dropdown {
  background-color: #62697a;
  color: white;

  .multiselect-dropdown-item:hover {
    background-color: #e0e0e0;
    color: black;
  }
}
