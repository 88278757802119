.dropdown-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 0.9rem;
  max-width: 100%;
  width: 100%;
  background-color: white;
  border-radius: 0.35rem;

  .dropdown-selector {
    max-width: 100%;
    height: 30px;
    padding: 0 5px;
    user-select: none;
    position: relative;
    border-radius: 0.35rem;
    border: 1px solid #cacfe7;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      width: calc(100% - 20px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .dropdown-option-list {
    border-radius: 0.35rem;
    border: 1px solid #cacfe7;
    position: absolute;
    top: 30px;
    width: 100%;
    z-index: 20;
    display: flex;
    flex-direction: column;
    background-color: white;
    cursor: default;
    max-height: 150px;
    min-height: 30px;
    height: fit-content;
    overflow-y: scroll;

    input {
      height: 30px;
      font-size: 0.8rem;
    }

    .dropdown-option {
      align-items: center;
      min-height: 30px;
      user-select: none;
      cursor: pointer;
      padding: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    .dropdown-option:hover {
      background-color: #e0e0e0;
    }
  }
}

.dark-theme .dropdown-container {
  background-color: #62697a;
  color: white;

  .dropdown-option-list {
    background-color: #62697a;
    color: white;

    input {
      background-color: #62697a;
      color: white;
    }

    .dropdown-option:hover {
      background-color: #e0e0e0;
      color: black;
    }
  }
}
