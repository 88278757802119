.column {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: width 0.3s ease-in, height 0.3s ease-in, opacity 0.3s ease-in;
  margin: 0 10px;
}

.column:last-child {
  margin-right: 0;
}

.column:first-child {
  margin-left: 0;
}
