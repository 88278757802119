#overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(128, 128, 128, 0.38);
}

.modal-frame {
  min-height: 200px;
  min-width: 300px;
  background-color: white;
  position: relative;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 1px 15px 1px rgb(62 57 107 / 7%);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;

  .modal-close-button {
    right: 5px;
    top: 5px;
    position: absolute;
    cursor: pointer;
    width: auto;
    height: auto;
    font-size: 1.7rem;
  }
}

.dark-theme {
  .modal-frame {
    background-color: #010030;
    color: white;
  }
}
